import React                            from 'react';
import { graphql }                      from 'gatsby';
import Img                              from 'gatsby-image';
import { Helmet }                       from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';

import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import SEO         from '@interness/web-core/src/components/modules/SEO/SEO';

const ThanksPage = props => {
  const intl = props.intl.formatMessage;
  return (
    <>
      <SEO title={intl({ id: 'pages.thanks.seo_title' })}/>
      <Helmet>
        <meta name='robots' content='noindex'/>
        <meta name="robots" content="nofollow"/>
      </Helmet>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading><FormattedMessage id={'pages.thanks.heading'}/></Heading>
        {props.location.state ?
          <div>
            <p><FormattedMessage id='pages.thanks.contentWithoutName1' values={{ name: props.location.state.name }}/>
            </p>
            <p><FormattedMessage id='pages.thanks.contentWithoutName2' values={{ email: props.location.state.email }}/>
            </p>
          </div>
          :
          <div>
            <p><FormattedMessage id='pages.thanks.contentWithoutName1'/></p>
            <p><FormattedMessage id='pages.thanks.contentWithoutName2'/></p>
          </div>
        }
        <Spacer/>
      </Wrapper>
    </>
  );
};

export default injectIntl(ThanksPage);

export const query = graphql`
  query {
    headerImage: file(relativePath: {eq: "headers/contact.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;